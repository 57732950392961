<template>
  <section class="widget-container">
    <div
      v-if="!isFirstLoadComplete"
      class="loading-spinner">
      <v-progress-circular
        indeterminate
        color="secondary"/>
    </div>

    <div v-else-if="KPIs.length">
      <header class="widget-actions">
        <CyInputsDateTimeRange
          :relative-options="$static.kpiDateTimeFilterOptions"
          :value="datePeriodFilter.range || datePeriodFilter.value"
          future-dates-disabled
          @change="(value) => { datePeriodFilter = value }">
          <template #activator="{ on, label }">
            <CyButton
              theme="primary"
              variant="secondary"
              icon="event"
              v-on="on">
              {{ label }}
              <v-icon
                dense
                class="ml-1 mr-n2">
                arrow_drop_down
              </v-icon>
            </CyButton>
          </template>
        </CyInputsDateTimeRange>
        <CyFormsWidget
          v-if="$cycloid.permissions.canDisplay('CreateKPI')"
          :project-canonical="projectCanonical"/>
      </header>

      <transition-group
        v-if="generalKPIs"
        name="slide-fade-top"
        tag="div"
        class="widget-grid mb-11">
        <component
          v-for="KPI in _.sortBy(generalKPIs, ['name'])"
          :key="KPI.canonical"
          :kpi="KPI"
          :can-edit="!loadingKPIs"
          @favorited="GET_KPIS"
          @unfavorited="GET_KPIS"
          :is="getWidgetComponent(KPI)"/>
      </transition-group>

      <v-expansion-panels
        v-model="openPanels"
        accordion
        multiple>
        <v-expansion-panel
          v-for="({ env, kpis }) in KPIsByEnv"
          :key="env">
          <v-expansion-panel-header>
            <template #actions>
              <v-icon
                dense
                class="order-first mr-3">
                $expand
              </v-icon>
            </template>
            <div class="order-last d-flex align-center">
              <CyAvatar
                :item="_.find(envs, { canonical: env })"
                class="mr-2"
                sm/>
              <h2>
                {{ env }}
              </h2>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <component
              v-for="KPI in _.sortBy(kpis, ['name'])"
              :key="KPI.canonical"
              :kpi="KPI"
              :can-edit="!loadingKPIs"
              @favorited="GET_KPIS"
              @unfavorited="GET_KPIS"
              :is="getWidgetComponent(KPI)"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div
      v-else
      class="empty-state">
      <img
        class="empty-state__image"
        src="/static/images/kpi-empty-state.svg"
        alt="Key performance indicators">
      <div>
        <span class="empty-state__title">{{ $t('noMetricsConfigured') }}</span>
        <p class="empty-state__description">
          {{ $t('addFirstMetric') }}
          <a
            class="cy-link"
            :href="$docLinks.project.kpis"
            rel="noopener noreferrer"
            target="_blank">{{ $t('learnMore') }}</a>
        </p>
      </div>
      <CyFormsWidget
        v-if="$cycloid.permissions.canDisplay('CreateKPI')"
        class="empty-state__btn"
        :project-canonical="projectCanonical"
        :menu-props="{ 'offset-x': true }"/>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { constructBreadcrumb, getWidgetComponent } from '@/utils/helpers'
import CyFormsWidget from '@/components/forms/widget'
import CyKpiWidgetsCodeCoverage from '@/components/kpi-widgets/code-coverage'
import CyKpiWidgetsTimeToRelease from '@/components/kpi-widgets/time-to-release'
import CyKpiWidgetsSuccessFailure from '@/components/kpi-widgets/success-failure'
import CyKpiWidgetsBuildHistory from '@/components/kpi-widgets/build-history'
import CyKpiWidgetsBuildTime from '@/components/kpi-widgets/build-time'
import CyKpiWidgetsBuildFrequency from '@/components/kpi-widgets/build-frequency'
import CyInputsDateTimeRange from '@/components/inputs/date-time-range.vue'

export default {
  name: 'CyPageProjectMetrics',
  components: {
    CyFormsWidget,
    CyKpiWidgetsCodeCoverage,
    CyKpiWidgetsTimeToRelease,
    CyKpiWidgetsSuccessFailure,
    CyKpiWidgetsBuildHistory,
    CyKpiWidgetsBuildTime,
    CyKpiWidgetsBuildFrequency,
    CyInputsDateTimeRange,
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.$t('routes.projectMetrics'), [
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  data: () => ({
    isFirstLoadComplete: false,
    stopWatchingFilters: null,
    openPanels: [],
  }),
  computed: {
    ...mapState('organization/project/kpi', {
      KPIs: (state) => state.KPIs,
      loadingKPIs: (state) => state.isFetchInProgress.KPIs,
      kpiFilters: (state) => state.filters,
    }),
    ...mapGetters('organization/project', [
      'envs',
    ]),
    ...mapGetters([
      'projectCanonical',
    ]),
    $static () {
      return {
        kpiDateTimeFilterOptions: [
          {
            label: this.$tc('forms.field.timeRangeLastDays', 1),
            value: '1d',
          },
          {
            label: this.$t('forms.field.timeRangeLastWeek'),
            value: '1w',
          },
          {
            label: this.$tc('forms.field.timeRangeLastMonths', 1),
            value: '1M',
          },
          {
            label: this.$tc('forms.field.timeRangeLastMonths', 3, { months: 3 }),
            value: '3M',
          },
          {
            label: this.$t('forms.field.timeRangeLastYear'),
            value: '1y',
          },
        ],
      }
    },
    canSeeKPIs () {
      return this.$cycloid.permissions.canDisplay('GetKPIs')
    },
    KPIsByEnv () {
      return _.chain(this.KPIs)
        .groupBy('environment_canonical')
        .omit('undefined')
        .map((kpis, env) => ({ env, kpis }))
        .value()
    },
    generalKPIs () {
      return _.groupBy(this.KPIs, 'environment_canonical').undefined
    },
    datePeriodFilter: {
      get () {
        return this.kpiFilters.datePeriod
      },
      set (value) {
        this.SET_KPI_FILTERS({
          key: 'datePeriod',
          value,
        })
      },
    },
  },
  watch: {
    KPIsByEnv () {
      this.KPIsByEnv.forEach((_, index) => { this.openPanels.push(index) })
    },
  },
  async created () {
    await this.setup()
    this.stopWatchingFilters = this.$watch('datePeriodFilter', async function () {
      await this.getKPIs()
    })
  },
  beforeDestroy () {
    this.stopWatchingFilters()
  },
  methods: {
    ...mapActions('organization/project/kpi', [
      'GET_KPIS',
    ]),
    ...mapMutations('organization/project/kpi', [
      'SET_KPI_FILTERS',
    ]),
    getWidgetComponent,
    async setup () {
      await this.$evaluateUserActions(['GetKPIs'])
      this.datePeriodFilter = {
        label: this.$t('forms.field.timeRangeLastWeek'),
        range: '1w',
        value: this.getDefaultDateTimeRangeFilterValues(),
      }
      await this.getKPIs()
    },
    async getKPIs () {
      if (!this.canSeeKPIs) return
      await this.GET_KPIS()
      this.$toggle.isFirstLoadComplete(true)
    },
    getDefaultDateTimeRangeFilterValues () {
      const referenceTS = new Date()
      const sevenDaysAgoTS = $date.subDays(referenceTS, 7)
      const begin = this.roundTimestamp(sevenDaysAgoTS)
      const end = this.roundTimestamp(referenceTS)
      return { begin, end }
    },
    roundTimestamp (timestamp) {
      return $date.format($date.startOfSecond(timestamp), 'T')
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.projectMetrics',
        addFirstMetric: 'Add your first metric to start showing some key performance indicators for your pipelines.',
        noMetricsConfigured: 'No metrics configured on this project',
      },
      es: {
        title: '@:routes.projectMetrics',
        addFirstMetric: 'Agregue su primera métrica para comenzar a mostrar algunos indicadores clave de rendimiento para sus pipelines.',
        noMetricsConfigured: 'No hay métricas configuradas en este proyecto',
      },
      fr: {
        title: '@:routes.projectMetrics',
        addFirstMetric: 'Ajoutez votre première métrique pour commencer à afficher des indicateurs clés de performance pour vos pipelines.',
        noMetricsConfigured: 'Aucune métrique configurée sur ce projet',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-container {
  flex-grow: 1;
}

.widget-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px;

  > * + * {
    margin-left: 16px;
  }

  &__range {
    color: get-color("primary", "light-3");
  }
}

.widget-grid {
  $widget-spacing: 24px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  column-gap: $widget-spacing;
  row-gap: $widget-spacing;

  > * {
    margin: 0;
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 42px 0;
  text-align: center;

  &__title {
    color: get-color("primary", "light-2");
    font-size: map.get($font-sizes, "lg");
    font-weight: $font-weight-bold;
  }

  &__description {
    max-width: 416px;
    margin: 8px auto 0;
    color: get-color("primary", "light-3");
  }

  &__image {
    flex-grow: 0;
    margin-bottom: 40px;
  }

  &__btn {
    margin-top: 32px;
  }
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.slide-fade-top {
  &-enter-active,
  &-leave-active {
    transition:
      transform 0.2s ease,
      opacity 0.2s ease;
  }

  &-enter,
  &-leave-to {
    transform: translateY(-8px) !important;
    opacity: 0;
  }
}

::v-deep .v-expansion-panel {
  &::after {
    border-top: 0;
  }

  &-header {
    padding: 0;
  }

  &-content {
    display: grid;
    padding-top: 5px;
    padding-bottom: 16px;
  }

  &-content__wrap {
    @extend .widget-grid;

    padding: 0;
  }
}
</style>
